body {
  background-color: #000000;

  #animeLoop {
    z-index: 10;
    position: fixed;
  }
  #quoteZone {
    z-index: 20;
  }

  .stretchToFill{
    padding: 0px;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    background: no-repeat center center fixed;
    background-size: cover;
  }

  .stretchedToFit{
    background: no-repeat center center fixed;
    background-size: fill;
    min-width:100vw;
    min-height:100vh;
    width: 100%;
    height: 100%;
  }

  #quoteZone{
    position: fixed;
    top: -20px; /* or whatever top you need */
    left: 50%;
    width: 100vw;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 40px;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color:white;
    text-align: center;
    @include stroke(3,#000000);
  }

  .originalSize{
    background-repeat: no-repeat;
    position:absolute;
    padding: 0px;
    min-width:100vw;
    min-height:100vh;
  }

  #logo {
    z-index: 1;
    width: 313px;
    height: 309px;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    background-image: url("../img/anime2inspire-sticker.png");
    background-size: contain;
    background-repeat: none;
  }


}
